import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import ListIcon from '@material-ui/icons/List';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { render } from '@testing-library/react';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useApolloClient, useQuery, gql, NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';

import CustomerAutoComplete from '../components/CustomerAutocomplete';

const TODO_LIST = gql`
  query TODOS($searchKey: String!){
    todos(searchKey: $searchKey){
      nodes{
        id
        title
        description
        customerId
      }
    }
  }
`;

const CUSTOMER_AUTOCOMPLETE_LIST = gql`
  query Customers($searchKey: String!){
    customers(searchKey: $searchKey){
      nodes{
        id
        name
        contactNumber
        areaName
      }
    }
  }
`;

const ADD_TODO = gql`
  mutation createTodo($input: CreateTodoInput!) {
    createTodo(input: $input) {
      id
      title
      description
      customerId
    }
  }
`;

const UPDATE_TODO = gql`
  mutation updateTodo($input: UpdateTodoInput!) {
    updateTodo(input: $input) {
      id
      title
      description
      customerId
    }
  }
`;

// Place this here else it will create instance each time and creates error
const Transition = React.forwardRef((props, ref) => {
  return <Slide style={{border: 'red 1px solid'}} direction="up" ref={ref}  {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    //zIndex: 1000,
    bottom: theme.spacing(7),
    right: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  TextField:{
    width: '100%'
  },

}));

export default function Todo() {
  const client = useApolloClient();

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const [getTodos, { loading, data, error }] = useLazyQuery(TODO_LIST, {
    variables: { searchKey: '' }},
    //fetchPolicy: "cache-and-network"}
  );

  const [getCustomersAutocompleteList, { loading: ldg, data: dt, error: err }] = useLazyQuery(CUSTOMER_AUTOCOMPLETE_LIST, {
    variables: { searchKey: '' }},
    //fetchPolicy: "cache-and-network"}
  );


  useEffect(() => {
    getTodos({ variables: { searchKey: '' } })
    getCustomersAutocompleteList({ variables: { searchKey: '' } })
  },[])

  const addHandler = (e) => {
    setOpen(true);
  }

  const editHandler = (id, e) => {
    setOpen(true)

    const todo = client.readFragment({
      id: `Todo:${id}`, // The value of the to-do item's unique identifier
      fragment: gql`
        fragment MyTodo on Todo {
          id
          title
          description
          customerId
        }
      `,
    });
    setFormData({...todo})
  }


  const editCustomerHandler = (e) => {
    const record = client.readFragment({
      id: `Customer:${e.targe.value}`, // The value of the to-do item's unique identifier
      fragment: gql`
        fragment CustomerRecord on Customer {
          id
          name
          contactNumber
          areaName
        }
      `,
    });
    setCustomer({...record})
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const [addTodo, { ddata }] = useMutation(ADD_TODO);
  const [updateTodo] = useMutation(UPDATE_TODO);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formData, setFormData] = useState({id: null, title: '', description: '', customerId: ''})
  const [customer, setCustomer] = useState({id: null, name: '', contactNumber: '', areaName: ''})

  const handleCustomerNameChange = function(event, value){
    setCustomer({...customer, ...value})
  }

  // const { loading, error, data, refetch, networkStatus } = useQuery(TODO_LIST, {
  //   variables: { searchKey: '' },
  //   notifyOnNetworkStatusChange: true,
  // });


  // if (networkStatus === NetworkStatus.refetch) return 'Refetching!';
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const submitForm = (e) => {
    e.preventDefault();
    if(formData.id && formData.id.length > 5){
      updateTodo({ variables: {input:
          { id: formData.id, title: formData.title, description: formData.description, customerId: customer.id } }});
    }else{
      addTodo({ variables: {input: {title: formData.title, description: formData.description, customerId: customer.id } }});
      getTodos()
    }
    setFormData({...formData, id: null, title: '', description: ''});
    handleClose();
  }


  return (
    <div>
      { data && data.todos.nodes.map(({ id, title, description }) => (
          <Accordion key={id}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions2-content"
            >
              <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                {description}
              </Typography>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
            <Button size="small" onClick={e => editHandler(id, e) }>Edit</Button>
              <Button size="small">Cancel</Button>
              <Button size="small" color="primary">
                Completed
              </Button>
            </AccordionActions>
          </Accordion>)) }

          {/* <button onClick={() => refetch()}>Refetch!</button> */}

      <Dialog id="newtask-dialog" ref={dialogRef} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add new task
            </Typography>
            <Button autoFocus color="inherit" onClick={e => submitForm(e)}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ width: '100%' }}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <CustomerAutoComplete label="Customer" fullWidth={true} options={dt && dt.customers.nodes } onChange={ handleCustomerNameChange } selectedValue={formData.customerId} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Name" value={customer.name} fullWidth={true} onChange={(e => setCustomer({...customer, name: e.target.value}) )} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Contact Number" value={customer.contactNumber} fullWidth={true} onChange={(e => setCustomer({...customer, contactNumber: e.target.value}) )} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Area Name" value={customer.areaName} fullWidth={true} onChange={(e => setCustomer({...customer, areaName: e.target.value}) )} />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Title" value={formData.title} fullWidth={true} onChange={(e => setFormData({...formData, title: e.target.value}) )} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Description" value={formData.description} multiline rowsMax={20} size='large'  fullWidth={true} onChange={(e => setFormData({...formData, description: e.target.value}) )} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>

      <Fab color="primary" aria-label="add" className={classes.fab} style={{zIndex:'3000'}} onClick={e => addHandler(e) }>
        <AddIcon />
      </Fab>

      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
        style={{position: 'fixed', bottom: 0, width: '100%'}}>
        <BottomNavigationAction label="Pending" icon={<ListIcon />} />
        <BottomNavigationAction label="InProgress" icon={<PlaylistPlayIcon />} />
        <BottomNavigationAction label="Completed" icon={<PlaylistAddCheckIcon />} />
      </BottomNavigation>
    </div>)
}


// export default withApollo(Todo)