import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { useApolloClient, useQuery, gql, NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LOGIN_USER = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      id
      authToken
      name
      role
      error
    }
  }
`;


export default function LoginPage() {
  const client = useApolloClient();
  const classes = useStyles();
  const history = useHistory();

  const [login, { data, error }] = useMutation(LOGIN_USER);

  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({username: '', password: ''})
  const submitForm = (e) => {
    e.preventDefault();
    console.log(formData)
    if(formData.username =='' || formData.password.length < 3){
      setShowAlert(true)
    }else{
      login({ variables: {input:
        {username: formData.username, password: formData.password} }}).then(({ data }) => {
          if(data?.login?.error){
            setShowAlert(true)
          }else{
            localStorage.setItem('authtoken', data.login.authToken);
            localStorage.setItem('username', data.login.name);
            localStorage.setItem('userrole', data.login.role);
            window.location.href="/";
          }
        })
        .catch(e => {
          console.log("error....", e)
          if(data?.login?.error){
            setShowAlert(true)
          }
        });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>

        <form className={classes.form} noValidate>
          {showAlert ? <Alert severity="error">Invalid Login</Alert> : null}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="username"
            value={formData.username}
            autoFocus
            onChange={(e => setFormData({...formData, username: e.target.value}) )}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formData.password}
            onChange={(e => setFormData({...formData, password: e.target.value}) )}
          />
          <Button
            style={{'padding': '15px'}}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={e => submitForm(e)}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}