import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import strftime from 'strftime';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TodayIcon from '@material-ui/icons/Today';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import { useApolloClient, useQuery, gql, NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import LoadingBar from 'react-top-loading-bar';
import CustomerAutoComplete from '../components/CustomerAutocomplete';

const HOLIDAYS_LIST = gql`
  query HOLIDAYS($searchKey: String!){
    holidays(searchKey: $searchKey){
      nodes{
        id
        holidayDate
        reason
        createdAt
        updatedAt
      }
    }
  }
`;

const ADD_HOLIDAY = gql`
  mutation createHoliday($input: CreateHolidayInput!) {
    createHoliday(input: $input) {
      holiday {
        id
        holidayDate
        reason
      }
    }
  }
`;

const UPDATE_HOLIDAY = gql`
  mutation updateHoliday($input: UpdateHolidayInput!) {
    updateHoliday(input: $input) {
      holiday {
        id
        holidayDate
        reason
      }
    }
  }
`;

const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($input: DeleteHolidayInput!){
    deleteHoliday(input: $input){
      holiday {
        id
        holidayDate
        reason
      }
    }
  }
`;

// Place this here else it will create instance each time and creates error
const Transition = React.forwardRef((props, ref) => {
  return <Slide style={{border: 'red 1px solid'}} direction="up" ref={ref}  {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    //zIndex: 1000,
    bottom: theme.spacing(7),
    right: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  TextField:{
    width: '100%'
  },

}));

export default function HolidaysPage() {
  const client = useApolloClient();
  const ref = useRef(null);
  const [progress, setProgress] = useState(0);

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const [getHolidays, { loading, data, error }] = useLazyQuery(HOLIDAYS_LIST, {
    variables: { searchKey: '' },
    fetchPolicy: 'network-only',
    onCompleted({ recs }) {
      setProgress(100)
    }
    //fetchPolicy: "cache-and-network"}
});

  useEffect(() => {
    getHolidays({ variables: { searchKey: '' } })
  }, [])

  const addHandler = (e) => {
    setOpen(true);
  }

  const editHandler = (id, e) => {
    setOpen(true)

    const holiday = client.readFragment({
      id: `Holiday:${id}`, // The value of the to-do item's unique identifier
      fragment: gql`
        fragment MyHoliday on Holiday {
          id
          holidayDate
          reason
        }
      `,
    });
    setFormData({...holiday})
  }

  const removeHandler = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const status = window.confirm("Are you sure?");
    if(status){
      setProgress(progress + 20)
      deleteHoliday({ variables: {input:{id: id} }});
    }else{
      console.log("false")
    }
  }

  const editHolidayHandler = (e) => {
    const record = client.readFragment({
      id: `Holiday:${e.target.value}`, // The value of the to-do item's unique identifier
      fragment: gql`
        fragment HolidayRecord on Holiday {
          id
          holidayDate
          reason
        }
      `,
    });
    setHoliday({...record})
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const [addHoliday, { ddata }] = useMutation(ADD_HOLIDAY, {
    onCompleted({ recs }) {
      getHolidays({ variables: { searchKey: '' } })
    }
  });
  const [updateHoliday] = useMutation(UPDATE_HOLIDAY);
  const [deleteHoliday, { rdata }] = useMutation(DELETE_HOLIDAY, {
    onCompleted({ recs }) {
      getHolidays({ variables: { searchKey: '' } })
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formData, setFormData] = useState({id: null, holidayDate: '', reason: ''})
  const [holiday, setHoliday] = useState({id: null, holidayDate: '', reason: ''})

  // const { loading, error, data, refetch, networkStatus } = useQuery(TODO_LIST, {
  //   variables: { searchKey: '' },
  //   notifyOnNetworkStatusChange: true,
  // });


  // if (networkStatus === NetworkStatus.refetch) return 'Refetching!';
  //if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const submitForm = (e) => {
    e.preventDefault();
    console.log(formData)

    if(formData.id && formData.id.length > 5){
      updateHoliday({ variables: {input:
        {id: formData.id, holidayDate: formData.holidayDate, reason: formData.reason} }});
    }else{
      addHoliday({ variables: {input: {holidayDate: formData.holidayDate, reason: formData.reason} }});
    }
    setFormData({...formData, id: null, holidayDate: '', reason: ''});
    handleClose();
  }

  const formattedDate = (date) => {
    return strftime('%d/%m/%Y', new Date(Date.parse(date)))
  }

  return (
    <div>
      <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <List className={classes.root} component="nav">
        { data && data.holidays.nodes.map(({ id, holidayDate, reason }) => (
            <>
            <ListItem key={id}>
              <ListItemAvatar>
                <Avatar>
                  <TodayIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={reason} secondary={formattedDate(holidayDate)} />
              <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={e => editHandler(id, e) }>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="remove" onClick={e => removeHandler(id, e) }>
                <HighlightOffIcon />
              </IconButton>
            </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))}
      </List>

      <Dialog id="newholiday-dialog" ref={dialogRef} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add new holiday
            </Typography>
            <Button autoFocus color="inherit" onClick={e => submitForm(e)}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ width: '100%' }}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Date" type="date" value={formData.holidayDate} fullWidth={true} onChange={(e => setFormData({...formData, holidayDate: e.target.value}) )}
                      InputLabelProps={{ shrink: true}} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Reason" value={formData.reason} fullWidth={true} onChange={(e => setFormData({...formData, reason: e.target.value}) )} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
      {localStorage.getItem('userrole') == 'admin' ?
        <Fab color="primary" aria-label="add" className={classes.fab} style={{zIndex:'3000'}} onClick={e => addHandler(e) }>
          <AddIcon />
        </Fab> : null
      }
    </div>)
}
