import React, { useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import ListIcon from '@material-ui/icons/List';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { render } from '@testing-library/react';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Place this here else it will create instance each time and creates error
const Transition = React.forwardRef((props, ref) => {
  return <Slide style={{border: 'red 1px solid'}} direction="up" ref={ref}  {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    //zIndex: 1000,
    bottom: theme.spacing(7),
    right: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  TextField:{
    width: '100%'
  },

}));

export default function ServiceCallPage() {
  const classes = useStyles();
  const dialogRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const addHandler = (e) => {
    setOpen(true);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button size="small" color="primary">Start</Button>
          <Button size="small" color="primary" onClick={e => addHandler(e) }>Map</Button>
          <Button size="small">Cancel</Button>

        </AccordionActions>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
        >
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Suresh" secondary="200 Beach road, East lane, Adyar, Chennai - 600020. Contact: 9999999999, 8888888888" />
          </ListItem>
          </List>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6} md={4}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemText primary="Call Number" secondary="234234234234" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="right">Make</TableCell>
                        <TableCell align="right">Model</TableCell>
                        <TableCell align="right">Warranty</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={"abc1"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                      <TableRow key={"abc2"}>
                        <TableCell component="th" scope="row"> Battery
                        </TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Exide</TableCell>
                        <TableCell align="right">Under Warranty</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

          </div>
        </AccordionDetails>
      </Accordion>


      <Dialog id="newtask-dialog" ref={dialogRef} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Map
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ width: '100%' }}>
          <iframe src="https://www.google.com/maps/embed?pb=!2d-183.54324!3d36.26577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzbCsDE1JzU2LjgiTiA5MsKwMzInMzUuNyJX!5e0!3m2!1sen!2sin!4v1615014552568!5m2!1sen!2sin" width="100%" height="800" style={{"border":"0"}} allowFullScreen="" loading="lazy"></iframe>
        </div>
      </Dialog>

      {/* <Fab color="primary" aria-label="add" className={classes.fab} style={{zIndex:'3000'}} onClick={e => addHandler(e) }>
        <AddIcon />
      </Fab> */}

      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
        style={{position: 'fixed', bottom: 0, width: '100%'}}>
        <BottomNavigationAction label="Pending" icon={<ListIcon />} />
        <BottomNavigationAction label="InProgress" icon={<PlaylistPlayIcon />} />
        <BottomNavigationAction label="Completed" icon={<PlaylistAddCheckIcon />} />
      </BottomNavigation>
    </div>)
}