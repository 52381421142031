import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import ListIcon from '@material-ui/icons/List';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect
} from "react-router-dom";

import Todo from './pages/Todo';
import DashboardPage from './pages/Dashboard';
import ServiceCallPage from './pages/ServiceCall';
import HolidaysPage from './pages/Holidays';
import LoginPage from './pages/Login';
import AttendancePage from './pages/Attendance';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  fab: {
    position: 'fixed',
    //zIndex: 1000,
    bottom: theme.spacing(7),
    right: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  TextField:{
    width: '100%'
  },

}));

export default function App() {
  const classes = useStyles();
  const dialogRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const [pathName, setPathName] = React.useState(window.location.pathname.replace("/", ""))

  const addHandler = (e) => {
    setOpen(true);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logout = (e) => {
    localStorage.removeItem('authtoken');
    localStorage.removeItem('username');
    localStorage.removeItem('userrole');
    window.location.href="/login";
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={"Dashboard"} component={NavLink} to="/" >
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem>

        <ListItem button key={"Holidays"} component={NavLink} to="/holidays" >
          <ListItemIcon><MailIcon /></ListItemIcon>
          <ListItemText primary={"Holidays"} />
        </ListItem>

        <ListItem button key={"Attendance"} component={NavLink} to="/attendance" >
          <ListItemIcon><MailIcon /></ListItemIcon>
          <ListItemText primary={"Attendance"} />
        </ListItem>

        <ListItem button key={"Service Calls"} component={NavLink} to="/servicecalls" >
          <ListItemIcon><MailIcon /></ListItemIcon>
          <ListItemText primary={"Service Calls"} />
        </ListItem>

        <ListItem button key={"Todos"} component={NavLink} to="/todo" >
          <ListItemIcon><MailIcon /></ListItemIcon>
          <ListItemText primary={"Todos"} />
        </ListItem>
        {['Service Calls', 'PM'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['My Profile'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
        <ListItem button key='Signout'>
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText primary='Signout' onClick={e =>  logout(e) } />
          </ListItem>
      </List>
    </div>
  );

  if(localStorage.getItem('authtoken') ===null){
    return <LoginPage />;
  }

  return (
    <div>
      {
        pathName !== 'login' ?
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon onClick={toggleDrawer("left", true)} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {window.location.pathname.replace("/", "").toUpperCase()}
            </Typography>
          </Toolbar>
        </AppBar> : null
      }
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}

      {/* <Todo /> */}
      <Switch>
        <Route exact path="/todo"><Todo /></Route>
        <Route exact path="/"><DashboardPage /></Route>
        <Route exact path="/servicecalls"><ServiceCallPage /></Route>
        <Route exact path="/holidays"><HolidaysPage /></Route>
        <Route exact path="/attendance"><AttendancePage /></Route>
        <Route exact path="/login"><LoginPage /></Route>
      </Switch>
    </div>
  );
}