import React, { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import strftime from 'strftime';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useApolloClient, useQuery, gql, NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import LoadingBar from 'react-top-loading-bar';
import { useSnackbar } from 'notistack';

const ATTENDANCE_LIST = gql`
  query ATTENDANCE($searchKey: String!){
    attendance(searchKey: $searchKey){
      nodes{
        id
        attendanceDate
        inTime
        outTime
      }
    }
  }
`;

const CREATE_ATTENDANCE = gql`
  mutation createAttendance($input: CreateAttendanceInput!) {
    createAttendance(input: $input) {
      attendance {
        id
        inTime
        outsideLoggingReason
      },
      errors
    }
  }
`;

const UPDATE_ATTENDANCE = gql`
  mutation updateAttendance($input: UpdateAttendanceInput!) {
    updateAttendance(input: $input) {
      attendance {
        id
        inTime
        outTime
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const currentTime = () => {
  return strftime('%d/%m/%Y %I:%M:%S %p', new Date())
}

export default function AttendanceCard(props) {
  const classes = useStyles();
  const client = useApolloClient();
  const ref = useRef(null);
  const [progress, setProgress] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [expanded, setExpanded] = React.useState(false);
  const [userName, setUserName] = React.useState(localStorage.getItem('username'));
  const [userRole, setUserRole] = React.useState(localStorage.getItem('userrole'));
  const [loggedInTime, setLoggedInTime] = React.useState(localStorage.getItem('loggedintime') === '1');
  const [loggedOutTime, setLoggedOutTime] = React.useState(!loggedInTime);

  const [getAttendance, { loading, data, error }] = useLazyQuery(ATTENDANCE_LIST, {
    variables: { searchKey: '' },
    fetchPolicy: 'network-only',
    onCompleted({ recs }) {
      setProgress(100)
    }
    //fetchPolicy: "cache-and-network"}
  });

  const [createAttendance, { ddata }] = useMutation(CREATE_ATTENDANCE, {
    onCompleted({ createAttendance }) {
      const errors = createAttendance?.errors;
      if(errors !== ''){
        enqueueSnackbar(errors, {
          preventDuplicate: true,
          variant: 'error',
        });
        localStorage.setItem('loggedintime', '');
        setLoggedInTime(false);
        setLoggedOutTime(false)
      }else{
        localStorage.setItem('loggedintime', '1');
        setLoggedInTime(true);
        setLoggedOutTime(!loggedInTime)
        getAttendance({ variables: { searchKey: '' } })
      }
    }
  });

  const [updateAttendance, { rdata }] = useMutation(UPDATE_ATTENDANCE, {
    onCompleted({ recs }) {
      localStorage.setItem('loggedintime', '0');
      setLoggedInTime(false);
      setLoggedOutTime(!loggedInTime)
      getAttendance({ variables: { searchKey: '' } })
    }
  });

  useEffect(() => {
    getAttendance({ variables: { searchKey: '' } })
  }, [])


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const logInTime = (e) => {
    e.preventDefault();
    const status = window.confirm("Are you sure to log intime?");
    if(status){
      createAttendance({ variables: {input:{outsideLoggingReason: ''} }});
    }else{
      return false;
    }
  }

  const logOutTime = (e) => {
    e.preventDefault();
    const status = window.confirm("Are you sure to log out time?");
    if(status){
      updateAttendance({ variables: {input:{} }});
    }else{
      return false;
    }
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {userRole.charAt(0).toUpperCase()}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={userName}
        subheader={currentTime()}
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          Make sure you have enabled GPS in your mobile before clicking the button
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {loggedInTime ? <Button size="small" variant="contained" color="secondary" onClick={e => logOutTime(e) }>Log OutTime</Button> : <Button size="small" variant="contained" color="primary" onClick={e => logInTime(e) }>Log InTime</Button>}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Attendance List:</Typography>
          <TableContainer >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">In Time</TableCell>
            <TableCell align="right">Out Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.attendance.nodes.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.attendanceDate}
              </TableCell>
              <TableCell align="right">{row.inTime}</TableCell>
              <TableCell align="right">{row.outTime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </CardContent>
      </Collapse>
    </Card>
  );
}