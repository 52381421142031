import React from 'react';
import Attendance from '../components/Attendance';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Paper from "@material-ui/core/Paper";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import ListIcon from '@material-ui/icons/List';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CallIcon from '@material-ui/icons/Call';
import BuildIcon from '@material-ui/icons/Build';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: 20,
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontFamily: "Roboto"
  }
}));

export default function DashboardPage(){
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return(
    <div className={classes.root} style={{padding: '15px'}}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Attendance className={classes.paper} />
        </Grid>
      </Grid>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
        style={{ position: 'fixed', bottom: 0, width: '100%'}}>
        {/* <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} /> */}
        <BottomNavigationAction label="Todos" component={Link} to="/todo" value="todo" icon={<ListIcon />} />
        <BottomNavigationAction label="Service Calls" component={Link} to="/servicecalls" value="servicecalls"  icon={<CallIcon />} />
        <BottomNavigationAction label="PM" icon={<BuildIcon />} />
      </BottomNavigation>
    </div>
  )
}