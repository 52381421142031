import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions } from '@material-ui/lab/Autocomplete';

export default function CustomerAutoComplete({label, options, onChange, selectedValue}) {
  const filterOptions = (options, { inputValue }) => customSearch(options, inputValue);

  const customSearch = (options, value) =>{
    return options.filter(function (e) {
      var re = new RegExp(value, 'g');
      return (e.name.match(re) || e.contactNumber.match(re)) ? true : false;
    });
  }

  return (
    <Autocomplete
      id="customer_id"
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ width: '100%' }}
      onChange={onChange}
      filterOptions={filterOptions}
      value={options.find(v => v.id === selectedValue) || {}}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    />
  );
}
