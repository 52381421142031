import React, { useRef, useEffect, useState } from 'react';

import strftime from 'strftime';
import { makeStyles } from '@material-ui/core/styles';

import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import { useApolloClient, useQuery, gql, NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import LoadingBar from 'react-top-loading-bar';
import AttendanceRow from '../components/AttendanceExpandableRow';
import { BlockRounded } from '@material-ui/icons';

const ATTENDANCE_LIST = gql`
  query ATTENDANCE($searchKey: String!){
    attendance(searchKey: $searchKey){
      nodes{
        id
        employeeName
        attendanceDate
        inTime
        outTime
      }
    }
  }
`;

const HOLIDAYS_LIST = gql`
  query HOLIDAYS($searchKey: String!){
    holidays(searchKey: $searchKey){
      nodes{
        id
        holidayDate
        reason
        createdAt
        updatedAt
      }
    }
  }
`;

const ADD_HOLIDAY = gql`
  mutation createHoliday($input: CreateHolidayInput!) {
    createHoliday(input: $input) {
      holiday {
        id
        holidayDate
        reason
      }
    }
  }
`;

const UPDATE_HOLIDAY = gql`
  mutation updateHoliday($input: UpdateHolidayInput!) {
    updateHoliday(input: $input) {
      holiday {
        id
        holidayDate
        reason
      }
    }
  }
`;

const DELETE_HOLIDAY = gql`
  mutation deleteHoliday($input: DeleteHolidayInput!){
    deleteHoliday(input: $input){
      holiday {
        id
        holidayDate
        reason
      }
    }
  }
`;

// Place this here else it will create instance each time and creates error
const Transition = React.forwardRef((props, ref) => {
  return <Slide style={{border: 'red 1px solid'}} direction="up" ref={ref}  {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  strongHeader: {
    fontWeight: 'bold',
    fontSize: '16px'
  },

}));

export default function AttendancePage() {
  const client = useApolloClient();
  const ref = useRef(null);
  const [progress, setProgress] = useState(0);

  const classes = useStyles();
  const dialogRef = useRef(null);
  const [open, setOpen] = React.useState(false);

  const [getHolidays, { lloading, dddata, eerror }] = useLazyQuery(HOLIDAYS_LIST, {
    variables: { searchKey: '' },
    fetchPolicy: 'network-only',
    onCompleted({ recs }) {
      setProgress(100)
    }
    //fetchPolicy: "cache-and-network"}
});


const [getAttendance, { loading, data, error }] = useLazyQuery(ATTENDANCE_LIST, {
  variables: { searchKey: '' },
  fetchPolicy: 'network-only',
  onCompleted({ recs }) {
    setProgress(100)
  }
  //fetchPolicy: "cache-and-network"}
});

useEffect(() => {
  getAttendance({ variables: { searchKey: '' } })
}, [])


  const addHandler = (e) => {
    setOpen(true);
  }

  const editHandler = (id, e) => {
    setOpen(true)

    const holiday = client.readFragment({
      id: `Holiday:${id}`, // The value of the to-do item's unique identifier
      fragment: gql`
        fragment MyHoliday on Holiday {
          id
          holidayDate
          reason
        }
      `,
    });
    setFormData({...holiday})
  }

  const removeHandler = (id, e) => {
    e.preventDefault();
    e.stopPropagation();
    const status = window.confirm("Are you sure?");
    if(status){
      setProgress(progress + 20)
      deleteHoliday({ variables: {input:{id: id} }});
    }else{
      console.log("false")
    }
  }

  const editHolidayHandler = (e) => {
    const record = client.readFragment({
      id: `Holiday:${e.target.value}`, // The value of the to-do item's unique identifier
      fragment: gql`
        fragment HolidayRecord on Holiday {
          id
          holidayDate
          reason
        }
      `,
    });
    setHoliday({...record})
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState(0);
  const [addHoliday, { ddata }] = useMutation(ADD_HOLIDAY, {
    onCompleted({ recs }) {
      getHolidays({ variables: { searchKey: '' } })
    }
  });
  const [updateHoliday] = useMutation(UPDATE_HOLIDAY);
  const [deleteHoliday, { rdata }] = useMutation(DELETE_HOLIDAY, {
    onCompleted({ recs }) {
      getHolidays({ variables: { searchKey: '' } })
    }
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formData, setFormData] = useState({id: null, holidayDate: '', reason: ''})
  const [holiday, setHoliday] = useState({id: null, holidayDate: '', reason: ''})

  // const { loading, error, data, refetch, networkStatus } = useQuery(TODO_LIST, {
  //   variables: { searchKey: '' },
  //   notifyOnNetworkStatusChange: true,
  // });


  // if (networkStatus === NetworkStatus.refetch) return 'Refetching!';
  //if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const submitForm = (e) => {
    e.preventDefault();
    console.log(formData)

    if(formData.id && formData.id.length > 5){
      updateHoliday({ variables: {input:
        {id: formData.id, holidayDate: formData.holidayDate, reason: formData.reason} }});
    }else{
      addHoliday({ variables: {input: {holidayDate: formData.holidayDate, reason: formData.reason} }});
    }
    setFormData({...formData, id: null, holidayDate: '', reason: ''});
    handleClose();
  }

  const formattedDate = (date) => {
    return strftime('%d/%m/%Y', new Date(Date.parse(date)))
  }

  return (
    <div>
      <LoadingBar
        color='#f11946'
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <div>
          <TableContainer>
            <Table aria-label="collapsible table" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.strongHeader}>Employee</TableCell>
                  <TableCell align="right" className={classes.strongHeader}>In-Time</TableCell>
                  <TableCell align="right" className={classes.strongHeader}>Out-Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  data && data?.attendance?.nodes.map((row) => (
                    <AttendanceRow key={row.id} row={row} />
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
      </div>


      {/* <Dialog id="newholiday-dialog" ref={dialogRef} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add new holiday
            </Typography>
            <Button autoFocus color="inherit" onClick={e => submitForm(e)}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <div style={{ width: '100%' }}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Date" type="date" value={formData.holidayDate} fullWidth={true} onChange={(e => setFormData({...formData, holidayDate: e.target.value}) )}
                      InputLabelProps={{ shrink: true}} />
                </Box>
                <Box display="flex" p={1} bgcolor="background.paper">
                  <TextField label="Reason" value={formData.reason} fullWidth={true} onChange={(e => setFormData({...formData, reason: e.target.value}) )} />
                </Box>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
      {localStorage.getItem('userrole') == 'admin' ?
        <Fab color="primary" aria-label="add" className={classes.fab} style={{zIndex:'3000'}} onClick={e => addHandler(e) }>
          <AddIcon />
        </Fab> : null
      } */}
    </div>)
}
